import ShareButtons from '@/components/scripts/ShareButtons/index.vue'

export default {
  name: 'CheckoutRates',
  components: {
    ShareButtons
  },
  data () {
    return {
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        mobileFirst: true,
        infinite: true,
        swipeToSlide: true,
        slidesToShow: 1,
        dots: true
      },
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        autoHeight: true,
        centeredSlides: true,
        pagination: {
          el: '.slick-dots',
          type: 'bullets',
          bulletActiveClass: 'slick-active',
          clickable: true,
          renderBullet: function (index, className) {
            return '<li role="presentation" class="' + className + '"><button role="tab">' + (index + 1) + '</button></li>'
          }
        }
      }
    }
  }
}
